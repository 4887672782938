interface YoutubeConsentOptions {
    overlayTemplateId: string;

    overlaySelector: string;

    playVideoBtnSelector: string;

    youtubeVendorId: string;
}

class YoutubeConsent {
    private element: HTMLElement;

    private options: YoutubeConsentOptions;

    private readonly tagName: string;

    private overlayTemplate: HTMLElement | null;

    private overlay: HTMLElement | null;

    private playVideoBtn: HTMLElement | null;

    constructor(element: HTMLElement, options?: YoutubeConsentOptions) {
        const defaults       = {
            overlayTemplateId:    'youTubeOverlay',
            overlaySelector:      '.youtube-overlay',
            playVideoBtnSelector: '.play-video-toggler',
            youtubeVendorId:      's30',
        };
        this.element         = element;
        this.options         = {...defaults, ...options};
        this.tagName         = this.element.tagName.toLowerCase();
        this.overlay         = null;
        this.overlayTemplate = null;
        this.playVideoBtn    = null;

        this.init();
    }

    init() {
        this.unifyAttributes();
        this.initElements();
        this.handleElementsVisibility();
        this.initEvents();
    }

    unifyAttributes(): void {
        if (this.tagName === 'iframe') {
            const srcAttribute    = this.element.getAttribute('src');
            const relSrcAttribute = this.element.getAttribute('rel-src');

            if (srcAttribute) {
                this.element.setAttribute('data-src', srcAttribute);
            } else if (relSrcAttribute) {
                this.element.setAttribute('data-src', relSrcAttribute);
            }
            this.element.removeAttribute('rel-src');
        }
    }

    initElements(): void {
        this.overlayTemplate = document.getElementById(this.options.overlayTemplateId);
        this.overlay = this.element.parentElement!.querySelector<HTMLElement>(this.options.overlaySelector);

        if (!this.overlay) {
            this.overlay = this.overlayTemplate!.cloneNode(true) as HTMLElement;
            this.overlay.removeAttribute('id');

            if (this.tagName === 'iframe' || this.element.querySelectorAll('img').length) {
                this.element.parentNode!.insertBefore(this.overlay, this.element.nextSibling);
            }

            if (this.tagName === 'iframe') {
                const src = this.element.getAttribute('data-src');
                if (src) {
                    const backgroundImageUrl           = this.getHighQualityThumbnailUrl(src);
                    this.overlay.style.backgroundImage = 'url(' + backgroundImageUrl + ')';
                }

            }
        }

        this.playVideoBtn = this.overlay!.querySelector<HTMLElement>(this.options.playVideoBtnSelector);
    }

    handleElementsVisibility(): void {
        this.handleVisibilityDependingOnConsent();

        let visibilityCheckNumber      = 0;
        const visibilityCheckMaxNumber = 10;

        const visibilityCheckFunction = setInterval(() => {
            if (this.isCmpLoaded() && window.cmpYoutubeConsentGranted !== true) {
                window.cmpYoutubeConsentGranted = this.isCmpYoutubeConsentGranted();
            }

            if (window.cmpYoutubeConsentGranted || ++visibilityCheckNumber === visibilityCheckMaxNumber) {
                this.handleVisibilityDependingOnConsent();
                clearInterval(visibilityCheckFunction);
            }
        }, 150);
    }

    initEvents() {
        if (window.cmpConsentCustomEventAdded !== true) {
            let checkNumber      = 0;
            const checkMaxNumber = 10;

            const checkFunction = setInterval(() => {
                if (this.isCmpLoaded() && window['cmpConsentCustomEventAdded'] !== true) {
                    /**
                     * @see https://help.consentmanager.net/books/cmp/page/cmp-events
                     */

                    window.__cmp!(
                        'addEventListener',
                        [
                            'consentcustom',
                            () => {
                                window.cmpYoutubeConsentGranted = this.isCmpYoutubeConsentGranted();
                                this.handleVisibilityDependingOnConsent();
                            },
                            false,
                        ],
                        null,
                    );

                    window.cmpConsentCustomEventAdded = true;
                }

                if (window.cmpConsentCustomEventAdded || ++checkNumber === checkMaxNumber) {
                    clearInterval(checkFunction);
                }
            }, 150);
        }

        if (this.playVideoBtn) {
            this.playVideoBtn.addEventListener('click', () => {
                if (this.isCmpLoaded()) {
                    /**
                     * @see https://help.consentmanager.net/books/cmp/page/javascript-api
                     */

                    window.__cmp!('setVendorConsent', [this.options.youtubeVendorId, 1]);
                } else {
                    window.localYoutubeConsentGranted = true;
                    const overlays                    = Array.from(document.querySelectorAll<HTMLElement>(this.options.overlaySelector))
                        .filter(e => e.id !== this.options.overlayTemplateId);
                    this.handleVisibilityForConsent(overlays);
                }
                this.element.dispatchEvent(new MouseEvent('click', {
                    bubbles:    true,
                    cancelable: true,
                    view:       window instanceof Window ? window : null,
                }));
            });
        }
    }

    getHighQualityThumbnailUrl(videoUrl: string): string {
        videoUrl = videoUrl.replace(/(https?:)?\/\/(www\.)?/g, 'https://www.');

        const urlObject = new URL(videoUrl);
        const videoId   = urlObject.pathname.split('/').slice(-1)[0];

        return 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
    }

    isCmpLoaded(): boolean {
        return typeof window.__cmp === 'function';
    }

    isCmpYoutubeConsentGranted() {
        /**
         * @see https://help.consentmanager.net/books/cmp/page/checking-consent-for-a-vendor
         */
        const cmpData = this.isCmpLoaded() ? window.__cmp!('getCMPData') : undefined;

        return (
            typeof cmpData === 'object' &&
            cmpData.vendorConsents &&
            this.options.youtubeVendorId in cmpData.vendorConsents &&
            cmpData.vendorConsents[this.options.youtubeVendorId]
        );
    }

    handleVisibilityDependingOnConsent(): void {
        const overlays =
                  Array.from(document.querySelectorAll<HTMLElement>(this.options.overlaySelector))
                      .filter(elem => elem !== this.overlayTemplate);

        if (this.isCmpLoaded()) {
            window.cmpYoutubeConsentGranted ?
                this.handleVisibilityForConsent(overlays) :
                this.handleVisibilityForNoConsent(overlays);
        } else {
            window.localYoutubeConsentGranted ?
                this.handleVisibilityForConsent(overlays) :
                this.handleVisibilityForNoConsent(overlays);
        }
    }

    getIframeSiblings(element: HTMLElement): HTMLIFrameElement[] {
        return Array
            .from(element.parentElement?.children || [])
            .filter(sib => sib !== element && sib.tagName === 'IFRAME')
            .map(sib => sib as HTMLIFrameElement);
    }

    handleVisibilityForConsent(overlays: HTMLElement[]): void {
        overlays.forEach((overlay: HTMLElement) => {
            overlay.style.display = 'none';
            const siblings          = this.getIframeSiblings(overlay);

            if (siblings.length) {
                this.enableIframeVideo(siblings);
            }
        });
    }

    handleVisibilityForNoConsent(overlays: HTMLElement[]): void {
        overlays.forEach((overlay: HTMLElement) => {
            overlay.style.display = 'flex';
            const iframeSiblings    = this.getIframeSiblings(overlay);
            if (iframeSiblings.length) {
                this.disableIframeVideo(iframeSiblings);
            }
        });
    }

    disableIframeVideo(elements: HTMLIFrameElement[]): void {
        elements.forEach((element: HTMLIFrameElement) => {
            const dataSrc = element.getAttribute('data-src');
            if (dataSrc !== null) {
                element.removeAttribute('src');
            }
        });
    }

    enableIframeVideo(elements: HTMLIFrameElement[]): void {
        elements.forEach((element: HTMLIFrameElement) => {
            const dataSrc = element.getAttribute('data-src');
            if (dataSrc !== null) {
                element.setAttribute('src', dataSrc);
            }
        });
    }
}

export default function initYoutubeConsent(youTubeTriggers: HTMLElement[]) {
    youTubeTriggers.forEach(element => {
        if (!element.classList.contains('youtube-initialized')) {
            new YoutubeConsent(element);
            element.classList.add('youtube-initialized');
        }
    });
}
